import NavBar from '../NavBar';

export default function Projects() {
  return (
    <>
      <NavBar/>
      <h1 id='sorry'>
        Sorry, nothing ready to be hosted here quite yet.<br/>
        Stay tuned.
      </h1>
    </>
  );
}
